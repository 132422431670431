import './bootstrap'

import { Alpine, Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm'
import initialiseAlpine from './alpine/init'

initialiseAlpine(Alpine)

window.Alpine = Alpine
Array.from(document.querySelectorAll('[x-cloak]')).forEach((e) => e.removeAttribute('x-cloak'))

Livewire.start()
